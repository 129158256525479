import React, { useEffect, useState } from "react";
import "./info-popup.scss";
import { useTranslation } from "react-i18next";

const PopupSocioEcnomics = ({ close, setClose, selectedFiles }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation("translations");

  const hasSocioEcnomic =
    selectedFiles?.includes("socio-economic") && selectedFiles?.length <= 3;

  useEffect(() => {
    setClose(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <div
      className={`socio-economics-popup ${
        hasSocioEcnomic ? "has-socio-ecnomic" : ""
      } ${
        isVisible && !close ? "socio-economics-show" : "socio-economics-hide"
      }`}
    >
      {t("mapClickInstruction")}
      <button
        className="socio-economics-close-btn"
        onClick={() => setClose(true)}
      >
        ×
      </button>
    </div>
  );
};

export default PopupSocioEcnomics;
