// Import necessary modules and styles
import React, { useMemo, useState } from "react";
import TerminalContactDetails from "../terminalContactDetails";
import classes from "./terminalSideBar.module.scss";
import TerminalDetails from "./details";

const TerminalSideBar = ({
  selectedTerminal = {},
  onClose,
  toRight = false,
  isInlandPort = false,
}) => {
  return (
    <div
      className={`${classes.sidebar} ${classes["sidebar-terminal"]} ${toRight ? classes["to-right"] : ""
        }`}
    >
      <div className={`${classes["sidebar-header"]} ${classes.terminal}`}>
        {/*} Add an onClick handler to the close icon */}
        <p className={classes.heading}>{selectedTerminal.properties.name}</p>
        <span
          className={classes["close-icon"]}
          style={{ float: "right" }}
          onClick={() => {
            onClose();
          }}
        >
          <img src="./images/close-icon.svg" alt="close" />
        </span>
      </div>

      <TerminalContactDetails isInlandPort={isInlandPort} data={selectedTerminal.properties} />

      <TerminalDetails
        geometry={selectedTerminal.geometry}
        details={selectedTerminal.properties}
      />

      {/* <div className={classes["kv-terminal-table-container"]}>
        <DataTable data={selectedRegionData} terminalTable terminal />
      </div> */}
    </div>
  );
};

export default TerminalSideBar;
