import React from "react";
import classes from "./singleInformation.module.scss";
import { useTranslation } from "react-i18next";

export default function SingleInformation({
  icon = "",
  label = "",
  description = "",
  lastUpdate = "",
  links = [],
  viaAPI = false,
}) {
  const { t } = useTranslation("sidebar");
  const { i18n } = useTranslation();
  return (
    <div className={classes["single-information-container"]}>
      <div className={classes["icon-container"]}>
        <img className={classes.image} src={icon} alt="" />
      </div>
      <div className={classes["detail-container"]}>
        <p className={classes.heading}>{label}</p>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <p className={classes.description}>
          <span>
            <b>
              {t("Data Source")} {viaAPI ? t("via API") : ""}
            </b>
            :{" "}
          </span>
          {links.map(({ href, label }, index) => (
            <a
              className={classes.link || "#"}
              target="_blank"
              href={href}
              rel="noreferrer"
            >
                  {t(label)}
              {index !== links?.length - 1 && <span>, </span>}
            </a>
          ))}
        </p>
        <p className={classes.description}>
          <b>{t("Last Update")}:</b> {lastUpdate[i18n.language]}
        </p>
      </div>
    </div>
  );
}
