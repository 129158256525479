
export const buildLegendList = (
    t,
    translation,
    filterSelections,
    mapColors
) => {
    return [
        {
            label: t("legend-section.commercialCities.label"),
            icon: "/images/industry.png", // You can use static imports here
            id: "commercialCities",
        },
        {
            label: t("legend-section.airport.label"),
            icon: "/images/Airports_2.svg", // You can use static imports here
            id: "airport",
        },
        {
            label: t("legend-section.inland-ports.label"),
            icon: "/images/Inland Ports_2.svg",
            id: "inlandPort",
        },
        {
            label: t("legend-section.kv-terminal.label"),
            icon: "/images/KV Terminals_1.svg",
            id: "kvTerminal",
        },
        {
            label: t("legend-section.transport-volumes.label"),
            icon: "/images/transport_volumes.png",
            id: "transportVolumes",
        },
        ...(filterSelections?.["LEAG Railways"]?.leagRailway
            ? [{ label: t("filters.leagRailway"), color: mapColors.railway.leag }]
            : []),
        ...(filterSelections?.["Railway Sidings"]?.railwaySiding
            ? [{ label: t("filters.railwaySiding"), color: mapColors.railway.siding }]
            : []),
        ...(filterSelections?.["Motorway"]?.motorway
            ? [{ label: t("filters.motorway"), color: mapColors.roads.motorway }]
            : []),
        ...(filterSelections?.["Main Road"]?.mainRoad
            ? [{ label: t("filters.mainRoad"), color: mapColors.roads.mainRoad }]
            : []),
        ...(filterSelections?.geplant?.geplant
            ? [{
                label: t("legend-section.geplant.label"),
                icon: "/images/planned_terminals.svg",

            },]
            : []),

        ...(filterSelections?.["Railway Map"]?.railwayMap
            ? [
                {
                    label: translation("Electrified Railroads"),
                    color: mapColors.railwayMap.electrified,
                },
                {
                    label: translation("Non Electrified Railroads"),
                    color: mapColors.railwayMap.nonElectrified,
                },
            ]
            : []),
        ...(filterSelections?.["Railway Map"]?.capacityInformation
            ? [
                {
                    label: translation("Single Track"),
                    color: mapColors.railwayMap.singleTrack,
                },
                {
                    label: translation("Double Track"),
                    color: mapColors.railwayMap.doubleTrack,
                },
                {
                    label: translation("Neutral"),
                    color: mapColors.railwayMap.neutral,
                },
            ]
            : []),
        ...(filterSelections?.["Railway Map"]?.plannedRailways
            ? [
                {
                    label: translation("PlannedRailways"),
                    color: mapColors.railwayMap.nonElectrified,
                },
            ]
            : []),
        ...(filterSelections?.ER_International?.railway_map
            ? [
                {
                    label: t("filters.electrified_railway_international"),
                    color: mapColors.railwayMap.electrified,
                },
            ]
            : []),
        ...(filterSelections?.ER_National?.railway_map
            ? [
                {
                    label: t("filters.electrified_railway_national"),
                    color: mapColors.railwayMap.electrified,
                },
            ]
            : []),
        ...(filterSelections?.international?.roads
            ? [
                {
                    label: t("filters.international_roads"),
                    color: mapColors.roads.mainRoad,
                },
            ]
            : []),
        ...(filterSelections?.national?.roads
            ? [
                {
                    label: t("filters.national_roads"),
                    color: mapColors.roads.mainRoad,
                },
            ]
            : []),

        {
            label: t("legend-section.transport-volume-data.label"),
            color: mapColors.transportVolumes.root,
            id: "transportVolumes",
        },
        {
            label: t("legend-section.toll-data.label"),
            color: mapColors.tollData.root,
            id: "tollData",
        },
    ];
};
