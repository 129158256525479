import React, { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import classes from "./index.module.scss";
import { useTranslation } from "react-i18next";
import geojsonDataLimit from './../../data/train-geojson.json'
import openRailwayData from './../../data/open-railway-data.json'
import store from "../../store";

const ALLOWED_KEYS = [
    "bridge",
    "electrified",
    "gauge",
    "voltage",
    "frequency",
    "maxspeed",
    "name",
    "ref",
];

const CapactityInformationMap = () => {
    const { mapColors = {} } = store((state) => state) || {};
    const { t, i18n } = useTranslation("translations");
    const [geoJSONKey, setGeoJSONKey] = useState(0);

    const getColor = (ref) => {
        let color;
        let refNo = parseInt(ref)
        const matchingEntry = openRailwayData.find(entry => entry["Strecken-Nr"] === refNo);
        const gleisanzahl = matchingEntry ? matchingEntry["Gleisanzahl"] : undefined;
        const neutralRefs = [6193];
        if (neutralRefs.includes(refNo)) {
            color = "neutral";
        } else {
            if (gleisanzahl?.toLowerCase() === 'zweigleisig' || gleisanzahl?.toLowerCase().startsWith('zweigleisig')) {
                color = "doubleTrack";
            } else if (gleisanzahl?.toLowerCase() === 'eingleisig') {
                color = "singleTrack";
            }
        }
        return mapColors.railwayMap[color];
    };

    const onEachFeature = (feature, layer) => {
        if (Object.keys(feature.properties).length) {
            let popupContent = "";
            const details = feature.properties;
            popupContent += `<table class=${classes["railway-table-container"]
                }><tr><th>${t("Attribute")}</th><th>${t("Value")}</th></tr>`;

            if (Object.keys(details).length > 0) {
                for (let [key, value] of Object.entries(details)) {
                    if (ALLOWED_KEYS.includes(key)) {
                        let k = key.replaceAll(":", " ");
                        k = t("tags." + k);
                        k = k.replace("tags.", "");

                        let v = isNaN(value) ? t("tags." + value) : value;
                        v = v.replace("tags.", "");
                        v = value?.includes("https://") ? "https:" + v : v;
                        popupContent += `<tr><td>${k}</td><td style="text-transform: capitalize;">${v}</td></tr>`;
                    }
                }
            }

            popupContent += `</table>`;
            layer.bindPopup(popupContent);
        }
    };

    const getLineStyle = (feature) => {
        const { ref } = feature.properties || {};
        return {
            color: getColor(ref),
            weight: 3,
            zIndex: 2,
        };
    };

    useEffect(() => {
        setGeoJSONKey((prevKey) => prevKey + 1);
    }, [i18n.language]);


    return (
        <div>
            <GeoJSON
                key={geoJSONKey}
                data={geojsonDataLimit}
                onEachFeature={onEachFeature}
                style={getLineStyle}
                zIndex={1}
            />
        </div>
    );
};

export default CapactityInformationMap;
