import React, { useMemo } from "react";
import classes from "./informationPopup.module.scss";
import CloseIcon from "../../icons/close.icon";
import SingleInformation from "./singleInformation";
import { useTranslation } from "react-i18next";
import { DATA_SOURCES } from "../../../utils/constants";

export default function InformationPopup({
  onClose = () => {},
  selectedCategoryID = "",
}) {
  const { t } = useTranslation("sidebar");

  const filterSelectedCategory = useMemo(() => {
    if (!selectedCategoryID) return null;

    return DATA_SOURCES.find((data) => data.id === selectedCategoryID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryID]);


  return (
    <div className={classes["information-popup-container"]}>
      <div className={classes.header}>
        <p className={classes.heading}>{t("legend")}</p>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div
        className={`${classes.details} ${
          filterSelectedCategory ? classes.block : ""
        }`}
      >
        {filterSelectedCategory ? (
          <SingleInformation
            label={t(`legend-section.${filterSelectedCategory.key}.label`)}
            description={t(
              `legend-section.${filterSelectedCategory.key}.description`
            )}
            lastUpdate={filterSelectedCategory.lastUpdate}
            icon={filterSelectedCategory.icon}
            links={filterSelectedCategory.links}
            viaAPI={filterSelectedCategory.via || false}
          />
        ) : (
          <>
            {DATA_SOURCES.map((data) => (
              <SingleInformation
                key={data.key}
                label={t(`legend-section.${data.key}.label`)}
                description={t(`legend-section.${data.key}.description`)}
                lastUpdate={data.lastUpdate}
                icon={data.icon}
                links={data.links}
                viaAPI={data.via || false}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
