import React, { useState, useEffect, useRef } from "react";
import classes from "./changeLog.module.scss";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";

export default function ChangeLog({ showInfo, setShowInfo = () => { } }) {
    const { t } = useTranslation("translations");
    const boxRef = useRef(null);

    const LOGS_DATA = [
        { label: 'New', text: 'Addition of planned railways.' },
        { label: 'New', text: 'Integration of qualitative capacity information from DB InfraGo for selected railway lines.' },
        { label: 'New', text: 'Addition of planned Terminals.' },
        { label: 'Changed', text: 'Update of information on terminals.' },
        { label: 'Changed', text: 'Adding toll data from 2022 to 2024.' },
        { label: 'Changed', text: 'Addition of data on transport volumes for the year 2023.' },
    ]

    return (
        <div className={classes["main"]} ref={boxRef}>
            {showInfo && (
                <div className={classes["main-container"]}>

                    <div className={classes["legend-container"]}>
                        <div className={classes.details}>
                            <div className={classes.header}>
                                <div className={classes["single-heading"]}>
                                    {t("changeLogHeading")} v1.1
                                </div>
                                <span
                                    className={classes["close-button"]}
                                    onClick={() => setShowInfo(false)}
                                    aria-label="Close popup"
                                >
                                    <FaTimes size={15} />
                                </span>
                            </div>
                            <div className={classes["singleDetail"]}>
                                {
                                    LOGS_DATA.map(({ label, text }) => (
                                        <p>
                                            <span>{t(label)}</span>: <span>{t(text)}</span>
                                            <br />
                                        </p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}
