import React, { useState, useEffect, useMemo, useRef } from "react";
import {
    MapContainer,
    TileLayer,
    GeoJSON,
    Marker,
    Popup,
    useMapEvents,
    WMSTileLayer,
    useMap,
    ZoomControl,
} from "react-leaflet";
import Papa from "papaparse";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./index.css";
import axios from "axios";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import TollDataTable from "../tollData/tollDataTable";

// Data Import
import bkr from "../../data/bkr (2).json";
import krg from "../../data/krg.json";
import SOEFA_2021 from "../../data/SOEFA_2021 (3).csv";
import PieChartSidebar from "../piechart";
import regionDdata from "../../data/regionData.json";
import airportsData from "../../data/Database/airports.json";

// icons import
import { CustomIcon } from "./icons/Custom.icon.js";

// custom components
import TerminalSideBar from "../terminalSideBar";
import { GEOInlandPorts } from "./GEOComponents/geoInlandPorts.js";
import { GEOAirport } from "./GEOComponents/geoAirport.js";
import Search from "./search/index.js";
import SideBar from "../sidebar/index.js";
import { axiosInstance } from "../../axios/axiosIntercepter.js";
import { GEOKVTerminals } from "./GEOComponents/geoKvTerminals";
import { GEOCommercialIndustries } from "./GEOComponents/GEOCommercialIndustries.js";
import { SearchIcon } from "./searchIcon";
import { TransportVolumes } from "./trasportVolumes";
import RoutingMachine from "./routing-machine/index.js";
import MainSidebar from "../mainSidebar/index.js";
import Compass from "./compass/index.js";
import MyLocation from "./myLocation/index.js";
import RailSidding from "../railSidding";
import LausitzMap from "./lausitzMap";
import leagRawilwayData from "../../data/Database/LEAG-Streckennetz_new.json";
import ER_InternationalData from "../../data/Database/Electrified_Railway_International.json";
import ER_NationalData from "../../data/Database/Electrified_Railway_National.json";
import InternationRoadsData from "../../data/Database/International_Roads.json";
import NationalRoadsData from "../../data/Database/National_Roads.json";
import Autobahn from "./../../data/Database/Autobahn_Lausitz.json";
import Bundesstrassen from "./../../data/Database/Bundesstrassen-Lausitz.json";
import store from "../../store/index.js";
import OpenRailwayMap from "../openRailwayMap";
import TollData from "../tollData";
import { WegweiserService } from "../../services/index.js";
import Legends from "../legends/index.js";
import DataSources from "../data-sources/index.js";
import Modal from "../sharedComponent/modal/index.js";
import { TERMINALS_DETAILS } from "../../../src/utils/constants";
import ZoomLevel from "./zoomLevel";
import FeedbackButton from "../feedbackButton";
import Footer from "../sharedComponent/footer";
import TollDataForm from 'components/tollData/tollDataForm';
import ChangeLog from 'components/mainSidebar/changeLog/changeLog';
import CapactityInformationMap from 'components/capacityInformation';
import PlannedRailways from 'components/plannedRailways';
import PopupSocioEcnomics from "components/socio-economic-info-popup";

// icons configurations
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = () => {
    const [selectedFiles, setSelectedFiles] = useState(["bkr", "krg"]);
    const [searchParams] = useSearchParams();
    const filterValue = searchParams.get("filter");
    const searchLocation = searchParams.get("search-location");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [state, setState] = useState("");
    const [selectedRegionFriendlyURL, setSelectedRegionFriendlyURL] = useState("");
    const [selectedRegionId, setSelectedRegionId] = useState("");
    const [selectedTerminal, setSelectedTerminal] = useState(null);
    const [selectedInLandPort, setSelectedInLandPort] = useState(null);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [nutsInfo, setNutsInfo] = useState(null);
    const [closeSocioEconomicsPopup, setCloseSocioEconomicsPopup] = useState(false);
    const borderIds = [
        "12062000",
        "12066000",
        "12061000",
        "12071000",
        "12052000",
        "14625000",
        "14626000",
    ];
    const [clickedLocationInfo, setClickedLocationInfo] = useState(null);
    const [steckenPolyline, setSteckenPolyline] = useState([]);
    const [roadsElbeElster, setRoadsElbeElster] = useState([]);
    const [openSteckenSideMap, setOpenSteckenSideMap] = useState(false);
    const [steckenSideMapData, setSteckenSideMapData] = useState({});
    const [roadsDahmeSpreewald, setRoadsDahmeSpreewald] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [loadingArray, setLoadingArray] = useState([
        "strecken",
        "roadsElbeElster",
        "roadsDahmeSpreewald",
        "lausitz",
    ]);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [lausitz, setLausitz] = useState([]);
    const [airports, setAirports] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [flyBackFunction, setFlyBackFunction] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [filterSelections, setFilterSelections] = useState({});
    const [railwaySidingRegions, setRailwaySidingRegions] = useState([]);
    const [showAllTransportVols, setShowAllTransportVols] = useState(false);
    const [directionMode, setDirectionMode] = useState(false);
    const [railwaySiding, setRailwaySiding] = useState(false);
    const [leagRailway, setLeagRailway] = useState(false);
    const [motorways, setMotorways] = useState(false);
    const [mainRoads, setMainRoads] = useState(false);
    const [directionPoints, setDirectionPoints] = useState({
        source: {},
        destination: {},
    });
    const [showShortestRoute, setShowShorttestRoute] = useState(false);
    const [showLongestRoute, setShowLongestRoute] = useState(false);
    const [isRailwayRouting, setIsRailwayRouting] = useState(false);
    const [sourceStation, setSourceStation] = useState(null);
    const [destinationStation, setDestinationStation] = useState(null);
    const [showInfo, setShowInfo] = useState(false)

    const language = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const langValue = queryParams.get("lang") || "en";
        return langValue;
    }, [window.location.search]);

    const location = useLocation();
    const navigate = useNavigate();

    const [tollDetailOfSelectedId, setTollDetailOfSelectedId] = useState([]);
    const [showTollPopup, setShowTollPopup] = useState(false);

    const [selectedRailwayMapStyle, setSelectedRailwayMapStyle] = useState({
        style: "Infrastructure",
        legend: {
            url: `https://www.openrailwaymap.org//legend-generator.php?zoom=3&style=standard&lang=${language}`,
            height: "166px",
        },
    });

    const [radius, setRadius] = useState(200); // for railwayTracks (radius in km)
    const [isSearchedClicked, setIsSearchedClicked] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1537);
    const [selectedCategoryID, setSelectedCategoryID] = useState(null);

    const [selectedCities, setSelectedCities] = useState([])
    const [tollDataFilters, setTollDataFilters] = useState({
        year: '2024',
        month: '03'
    })

    // Zustand store
    const { mapColors = {} } = store((state) => state) || {};

    const { t } = useTranslation("translations");
    const [popupOpen, setPopupOpen] = useState(null);

    useEffect(() => {
        if (filterSelections) {
            const showAll =
                filterSelections["Show All"] && filterSelections["Show All"].showAll;
            setShowAllTransportVols(showAll);

            const RailwaySiding = Boolean(
                railwaySidingRegions.length &&
                filterSelections["Railway Sidings"] &&
                filterSelections["Railway Sidings"].railwaySiding
            );
            setRailwaySiding(RailwaySiding);

            const LeagRailway =
                filterSelections["LEAG Railways"] &&
                filterSelections["LEAG Railways"].leagRailway;
            setLeagRailway(LeagRailway);

            const Motorways =
                filterSelections["Motorway"] && filterSelections["Motorway"].motorway;
            setMotorways(Motorways);

            const MainRoads =
                filterSelections["Main Road"] && filterSelections["Main Road"].mainRoad;
            setMainRoads(MainRoads);
        }
    }, [filterSelections]);

    // utility functions
    const deleteCallFromLoadingArray = (call) => {
        let newLoadingArray = [...loadingArray];
        newLoadingArray = newLoadingArray.filter((l) => l !== call);
        setLoadingArray(newLoadingArray);
    };

    const setMsg = (call) => {
        setLoadingMessage(`${t(call)} ${t("data is loading")}....`);
    };

  const fetchDataIteratively = async (endpoint, setter, pageSize = 500) => {
    const baseUrl = "https://core-backend.distill-lausitz.de/api";
    try {
      let pageNo = 0;
      let allRecords = [];
      while (true) {
        const response = await axiosInstance.get(
          `${baseUrl}/${endpoint}?pageNo=${pageNo}&pageSize=${pageSize}`
        );
        if (response) {
          const geojson = response?.data?.geoJson?.features || [];
          if (geojson?.length === 0) {
            break;
          }
          allRecords = [...allRecords, ...geojson];
          pageNo++;
        }
      }

            setter(allRecords);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // api calls
    const fetchStreckenPolyline = async () => {
        if (!steckenPolyline.length)
            try {
                setLoader(true);
                setMsg(t("Railways"));
                await fetchDataIteratively("getStreckenPolyline", setSteckenPolyline);
            } finally {
                setLoader(false);
            }
    };

    const fetchRoadsElbeElster = async () => {
        console.log(
            roadsElbeElster.length,
            roadsDahmeSpreewald.length,
            lausitz.length,
            "asdasd"
        );
        if (roadsElbeElster.length === 0)
            try {
                setLoader(true);
                setMsg("Roads Elbe Elster");
                await fetchDataIteratively("getElbeElster", setRoadsElbeElster);
            } finally {
                setLoader(false);
            }
    };

    const fetchDahmeSpreewald = async () => {
        if (roadsDahmeSpreewald.length === 0)
            try {
                setLoader(true);
                setMsg("Roads Dahme Spreewald");
                await fetchDataIteratively("getDahmeSpreewald", setRoadsDahmeSpreewald);
            } finally {
                setLoader(false);
            }
    };

    const fetchLausitz = async () => {
        if (lausitz.length === 0)
            try {
                setLoader(true);
                setMsg("Lausitz");
                await fetchDataIteratively("getLausitz", setLausitz);
            } finally {
                setLoader(false);
            }
    };
    // Fetches airports details
    const fetchAirports = async (pageNo = 0, pageSize = 5) => {
        if (!airports.features)
            try {
                setAirports(airportsData);
                // setMsg("Airports");
                // const response = await WegweiserService.getAirports(pageNo, pageSize);
                // if (response) {
                //   const geojson = response?.data?.geoJson;
                //   setAirports(geojson);
                // }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
    };
    // Fetches KV-terminal details

    const fetchTerminals = () => {
        if (!terminals.length)
            try {
                setTerminals(TERMINALS_DETAILS);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(SOEFA_2021);
            const reader = response.body.getReader();
            const result = await reader.read();
            const text = new TextDecoder().decode(result.value);

            Papa.parse(text, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    const formattedData = result.data.map((row) => {
                        const region = row.Indikatoren;
                        const year = row["2021"]; // Adjust this based on your actual column names
                        const indicators = Object.keys(row).filter(
                            (key) => key !== "Indikatoren" && key !== "2021"
                        ); // Exclude region and year columns
                        const data = { [year]: {} };

                        indicators.forEach((indicator) => {
                            data[year][indicator] = row[indicator];
                        });

                        return { region, data };
                    });
                },
            });
        };

        fetchData();
    }, []);

    const handleSearchResult = async (location) => {
        try {
            const response = await axiosInstance.get(
                `https://nominatim.openstreetmap.org/details.php?place_id=${location.raw.place_id}&addressdetails=1&hierarchy=0&group_hierarchy=1&format=json`
            );
            if (response.data.extratags["ref:nuts:3"]) {
                setNutsInfo(response.data.extratags["ref:nuts:3"]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setSelectedLocation(location);
        const markerPopup = document.querySelector(".leaflet-marker-pane");
        if (markerPopup) {
            markerPopup.click();
        }
    };

    const lausitzFeatures = bkr.features.filter(
        (feature) => feature.properties.BKR === "Revier Lausitz"
    );

    const lausitzBorders = krg.features.filter((item) =>
        borderIds.includes(item.properties.SN_KRG)
    );

    const handleFileSelection = (fileId) => {
        const isSelected = selectedFiles.includes(fileId);
        if (isSelected) {
            setSelectedFiles((prev) => prev.filter((id) => id !== fileId));
            if (fileId === "openRailwayMap") {
                setRadius(null);
            }
            if (fileId === filterValue) {
                updateUrlFilter();
            }
        } else {
            setSelectedFiles((prev) => [...prev, fileId]);
            if (fileId === "roadTacks") {
                const data = {
                    Motorway: {
                        motorway: true,
                    },
                    "Main Road": {
                        mainRoad: true,
                    },
                };
                setMotorways(true);
                setMainRoads(true);
                setMainRoads(true);
                setFilterSelections((prev) => ({ ...prev, ...data }));
            }
        }
    };

    const handleCloseSideBar = (setNull) => {
        moveToLausitzMap()
        setOpenSidebar(false);
        setOpenSteckenSideMap(false);
        setFlyBackFunction(true);
        setShowTollPopup(false);
        if (setNull === "terminal") {
            setSelectedTerminal(null);
        } else if (setNull === "inland") {
            setSelectedInLandPort(null);
        }
    };

    const reverseGeocode = async (lat, lng) => {
        if (lat && lng)
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const region = data?.address?.county;

                const matchingOption = regionDdata.find((option) => {
                    const optionRegionPrefix = option.region.substring(0, 5);
                    if (data.address.county) {
                        return optionRegionPrefix === data.address.county.substring(0, 5);
                    } else {
                        return optionRegionPrefix === data.address.city.substring(0, 5);
                    }
                });

                const result = await WegweiserService.getSpecificRegionDetails(
                    matchingOption.region
                );

                const regionId = result?.data[0]?.id;

                if (matchingOption && regionId) {
                    setSelectedRegion(matchingOption.region);
                    setState(data?.address?.state);
                    setSelectedRegionFriendlyURL(result?.data[0]?.friendlyUrl);
                    // setLoadingState(data?.address?.state);
                    setSelectedRegionId(regionId);
                    setOpenSidebar(true);
                } else {
                    console.log("No matching option found");
                }

                setClickedLocationInfo(data);
                return data;
            } catch (error) {
                console.error("Error fetching reverse geocoding data:", error);
            }
    };

    const handleTerminals = (selectedTerminalDetails) => {
        setSelectedTerminal(selectedTerminalDetails);
    };

    const handleInLandPort = (selectedTerminalDetails) => {
        setSelectedInLandPort(selectedTerminalDetails);
    };

    const geoJsonFiles = useMemo(() => {
        return [
            {
                id: "bkr",
                data: lausitzFeatures,
                color: mapColors.steckenPolyline.bkr,
            },
            { id: "krg", data: lausitzBorders, color: mapColors.steckenPolyline.krg },
        ];
    }, [steckenPolyline, airports]);

    const [searchInput, setSearchInput] = useState("");

    let bounds = L.latLngBounds([]);

    if (lausitzFeatures && lausitzFeatures.length > 0) {
        lausitzFeatures.forEach((feature) => {
            const geometry = feature.geometry;
            if (geometry) {
                switch (geometry.type) {
                    case "Polygon":
                        const coordinates = geometry.coordinates[0]; // Access the first array of coordinates for the exterior ring
                        if (coordinates && coordinates.length > 0) {
                            coordinates.forEach(([lng, lat]) =>
                                bounds.extend(L.latLng(lat, lng))
                            );
                        }
                        break;
                    case "MultiPolygon":
                        geometry.coordinates.forEach((polygon) => {
                            if (polygon && polygon.length > 0) {
                                polygon.forEach((ring) => {
                                    if (ring && ring.length > 0) {
                                        ring.forEach(([lng, lat]) =>
                                            bounds.extend(L.latLng(lat, lng))
                                        );
                                    }
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // Function to simplify the coordinates using Douglas-Peucker algorithm
    function simplifyCoordinates(coordinates, tolerance) {
        // Ensure the coordinates contain at least 3 points
        if (coordinates.length < 3) {
            return coordinates;
        }
        // Initialize the simplified coordinates with the first and last points
        const simplifiedCoordinates = [coordinates[0]];
        // Apply the Douglas-Peucker algorithm
        simplifyLine(
            coordinates,
            0,
            coordinates.length - 1,
            tolerance,
            simplifiedCoordinates
        );
        // Add the last point
        simplifiedCoordinates.push(coordinates[coordinates.length - 1]);
        return simplifiedCoordinates;
    }

    // Recursive function to apply Douglas-Peucker algorithm
    function simplifyLine(coordinates, start, end, tolerance, simplified) {
        let maxDistance = 0;
        let farthestIndex = 0;
        // Find the point farthest from the line between start and end points
        for (let i = start + 1; i < end; i++) {
            const distance = distanceToSegment(
                coordinates[i],
                coordinates[start],
                coordinates[end]
            );
            if (distance > maxDistance) {
                maxDistance = distance;
                farthestIndex = i;
            }
        }
        // If the farthest point is outside the tolerance, recursively simplify the line
        if (maxDistance > tolerance) {
            simplifyLine(coordinates, start, farthestIndex, tolerance, simplified);
            simplifyLine(coordinates, farthestIndex, end, tolerance, simplified);
        } else {
            // Otherwise, add the farthest point to the simplified coordinates
            simplified.push(coordinates[farthestIndex]);
        }
    }

    // Function to calculate the distance from a point to a line segment
    function distanceToSegment(point, start, end) {
        const [x, y] = point;
        const [x1, y1] = start;
        const [x2, y2] = end;
        const dx = x2 - x1;
        const dy = y2 - y1;
        const lengthSquared = dx * dx + dy * dy;
        const t = lengthSquared
            ? ((x - x1) * dx + (y - y1) * dy) / lengthSquared
            : -1;
        if (t < 0) {
            return Math.sqrt((x - x1) * (x - x1) + (y - y1) * (y - y1));
        } else if (t > 1) {
            return Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2));
        } else {
            const projectionX = x1 + t * dx;
            const projectionY = y1 + t * dy;
            return Math.sqrt(
                (x - projectionX) * (x - projectionX) +
                (y - projectionY) * (y - projectionY)
            );
        }
    }

    const fetchRelevantFile = async (id) => {
        handleFileSelection(id);
        try {
            setLoadingState(true);
            // if (id === "railway") {
            //   await fetchStreckenPolyline();
            // }
            if (id === "airport") {
                await fetchAirports();
            }
            if (id === "kvTerminal") {
                fetchTerminals();
            }
        } catch (error) {
            console.log({ error });
        } finally {
            setLoadingState(false);
        }
    };

    function MyComponent() {
        const map = useMapEvents({
            click: async (e) => {
                const { lat, lng } = e.latlng;
                const apiKey = "ce69f345f6524e888bc61db25cb23f7e";
                await reverseGeocode(lat, lng);
            },
        });
        return null;
    }

    const [moveBackToLausitz, setMoveBackToLausitz] = useState(false);

    const moveToLausitzMap = () => {
        setMoveBackToLausitz(true);
    };

    const MoveBackToLausitz = () => {
        const map = useMap();
        map.flyTo([51.5349597, 13.6215388], isSmallScreen ? 8.3 : 8.73);

        setMoveBackToLausitz(false);
        return null;
    };

    // Remove terminal popup if terminal option unchecked from category sidebar
    useEffect(() => {
        if (!selectedFiles?.includes("kvTerminal") && !filterSelections?.geplant?.geplant && selectedTerminal) {
            setSelectedTerminal("");
        }
    }, [selectedFiles, selectedTerminal]);

    useEffect(() => {
        if (filterValue) {
            fetchRelevantFile(filterValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1537);
        };

        // Add event listener for screen resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const updateUrlFilter = () => {
        const params = new URLSearchParams(location.search);
        params.delete("filter");
        navigate(
            {
                pathname: location.pathname,
                search: params.toString(),
            },
            { replace: true }
        );
    };

    useEffect(() => {
        if (selectedFiles.length) {
            if (!selectedFiles.includes("roadTacks")) {
                setMainRoads(null);
                setMotorways(null);

                setFilterSelections((prev) => ({
                    ...prev,
                    "Main Road": {},
                    Motorway: {},
                }));
            }
            if (selectedFiles.includes("railway")) {
                setFilterSelections((prev) => ({
                    ...prev,
                    "Railway Map": {
                        railwayMap: true,
                    },
                }));
            } else {
                setFilterSelections((prev) => ({
                    ...prev,
                    "Railway Map": {},
                }));
            }
        }
    }, [selectedFiles]);

    const showLegends = useMemo(() => {
        const filteredFiles = selectedFiles.filter(
            (file) => file !== "socio-economic" && file !== "railway"
        );
        return filteredFiles.length > 2 || selectedCities.length > 1;
    }, [selectedFiles, selectedCities.length]);


    const isAnyFilterisChecked = useMemo(() => {
        return Object.values(filterSelections).some((selection) =>
            Object.values(selection).some((value) => value === true)
        );
    }, [filterSelections]);

    const plannedRailways = filterSelections?.["Railway Map"]?.plannedRailways
    const capacityInformation = filterSelections?.["Railway Map"]?.capacityInformation
    const railwayMap = filterSelections?.["Railway Map"]?.railwayMap



    return (
        <>
            <div className="map-container">
                {loadingState ? (
                    <div className="message-popup">
                        <span>{loadingMessage}</span>
                    </div>
                ) : (
                    ""
                )}
                <MapContainer

                    className="leaflet-map"
                    center={[51.5349597, 13.6215388]}
                    zoom={8}
                    scrollWheelZoom={true}
                    zoomControl={true}

                >
                    <ZoomControl position="bottomright" />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        className={"grayscale"}
                    />
                    {/* <Compass /> */}
                    <ZoomLevel
                        zoomValue={isSmallScreen ? 8.3 : 8.73}
                        isSidebarOpen={isSidebarOpen}
                    />
                    <MyLocation />
                    {selectedFiles.includes("wmsLayer") && (
                        <WMSTileLayer
                            url="https://webgate.ec.europa.eu/getis/services/TENTec/tentec_public_corridors_ext/MapServer/WMSServer"
                            layers="1,2,3,4,5,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36"
                            format="image/png"
                            transparent={true}
                            version="1.3.0"
                        />
                    )}
                    {selectedLocation && (
                        <SearchIcon
                            lat={selectedLocation.x}
                            lng={selectedLocation.y}
                            icon={CustomIcon}
                            label={selectedLocation.label}
                            nutsInfo={nutsInfo}
                        />
                    )}
                    <MyComponent />
                    {directionMode &&
                        directionPoints.source &&
                        directionPoints.destination && (
                            <RoutingMachine
                                source={directionPoints?.source}
                                destination={directionPoints?.destination}
                                sourceStation={sourceStation}
                                destinationStation={destinationStation}
                                longestRoute={showLongestRoute}
                                shortestRoute={showShortestRoute}
                                filterSelections={filterSelections}
                            />
                        )}
                    {/* Render the GEOKVTerminals component*/}
                    {(selectedFiles.includes('kvTerminal') || filterSelections?.geplant?.geplant) && (
                        <GEOKVTerminals
                            selectedFiles={selectedFiles}
                            data={terminals}
                            handleTerminals={handleTerminals}
                            flyBackFunction={flyBackFunction}
                            geplant={filterSelections?.geplant?.geplant}
                            kvTerminal={selectedFiles.includes('kvTerminal')}
                        />)
                    }
                    {/* Search for location on Map */}
                    {isSidebarOpen && (
                        <div className="geo-search-sidebar">
                            <Search
                                provider={new OpenStreetMapProvider()}
                                onSearchResult={handleSearchResult}
                                searchInput={searchInput}
                                searchLocation={searchLocation}
                            />
                        </div>
                    )}
                    {/* LausitzMap Boundaries */}
                    <LausitzMap

                        geoJsonFiles={geoJsonFiles}
                        selectedFiles={selectedFiles}
                        reverseGeocode={reverseGeocode}
                    />

                    {/* Render the RailSidding */}
                    {railwaySiding && (
                        <RailSidding
                            filterSelection={filterSelections}
                            railwaySidingRegions={railwaySidingRegions}
                        />
                    )}
                    {/* Render the Motorway */}

                    {motorways && (
                        <GeoJSON
                            data={Autobahn}
                            style={() => ({
                                color: mapColors.roads.motorway,
                                weight: 2,
                                fillOpacity: 1,
                                fillColor: "#8BA5C1",
                            })}
                        />
                    )}
                    {/* Render the MainRoads */}

                    {mainRoads && (
                        <GeoJSON
                            data={Bundesstrassen}
                            style={() => ({
                                color: mapColors.roads.mainRoad,
                                weight: 3,
                                fillOpacity: 0.5,
                                fillColor: "#8BA5C1",
                            })}
                        />
                    )}

                    {/* Render the Airports */}
                    <GEOAirport
                        data={airports}
                        loading={loadingState}
                        selectedFiles={selectedFiles}
                    />

                    <GEOCommercialIndustries selectedCities={selectedCities} />

                    {/* Render the Inland Ports */}
                    <GEOInlandPorts selectedFiles={selectedFiles} handleInlandPort={handleInLandPort} />
                    {/* Render the Leag Railway */}
                    {leagRailway && (
                        <GeoJSON
                            data={leagRawilwayData}
                            style={() => ({
                                color: mapColors.railway.leag,
                                weight: 3,
                                fillOpacity: 0.15,
                                fillColor: "#ddc6e1",
                            })}
                        />
                    )}
                    {filterSelections?.ER_International?.railway_map && (
                        <GeoJSON
                            data={ER_InternationalData}
                            style={() => ({
                                color: mapColors.railwayMap.electrified,
                                weight: 3,
                                fillOpacity: 0.15,
                                fillColor: "#ddc6e1",
                            })}
                            zIndex={1}
                        />
                    )}

                    {filterSelections?.ER_National?.railway_map && (
                        <GeoJSON
                            data={ER_NationalData}
                            style={() => ({
                                color: mapColors.railwayMap.electrified,
                                weight: 3,
                                fillOpacity: 0.15,
                                fillColor: "#ddc6e1",
                            })}
                            zIndex={1}
                        />
                    )}

                    {filterSelections?.international?.roads && (
                        <GeoJSON
                            data={InternationRoadsData}
                            style={() => ({
                                color: mapColors.roads.mainRoad,
                                weight: 2,
                                fillOpacity: 0.5,
                                fillColor: "#47658c",
                            })}
                        />
                    )}

                    {filterSelections?.national?.roads && (
                        <GeoJSON
                            data={NationalRoadsData}
                            style={() => ({
                                color: mapColors.roads.mainRoad,
                                weight: 2,
                                fillOpacity: 0.5,
                                fillColor: "#47658c",
                            })}
                        />
                    )}

                    {/* Render the Railway */}
                    {/* <GEORailway
          data={steckenPolyline}
          selectedFiles={selectedFiles}
          setSteckenSideMapData={setSteckenSideMapData}
          setOpenSteckenSideMap={setOpenSteckenSideMap}
        /> */}
                    {/* Render the TransportVolumes */}
                    {selectedFiles.includes("transportVolumes") && (
                        <TransportVolumes
                            selectedFiles={selectedFiles}
                            filterSelections={filterSelections}
                            showAllTransportVols={showAllTransportVols}
                            setPopupOpen={setPopupOpen}
                        />
                    )}
                    {/* Reset the Map */}
                    {moveBackToLausitz && <MoveBackToLausitz />}
                    {/* Render the TollData */}

                    {selectedFiles.includes("tollData") && (
                        <TollData
                            setShowTollPopup={setShowTollPopup}
                            setTollDetailOfSelectedId={setTollDetailOfSelectedId}
                            setTollDataFilters={setTollDataFilters}
                            tollDataFilters={tollDataFilters}
                        />
                    )}

                    {plannedRailways && <PlannedRailways />}
                    {!plannedRailways && capacityInformation && <CapactityInformationMap />}
                    {!plannedRailways && !capacityInformation && railwayMap && (
                        <OpenRailwayMap
                            radius={radius}
                            style={selectedRailwayMapStyle?.style}
                            setIsSearchedClicked={setIsSearchedClicked}
                            isSearchedClicked={isSearchedClicked}
                            isInternationalRailwayAdded={filterSelections?.ER_International?.railway_map}
                            isNationalRailwayAdded={filterSelections?.ER_National?.railway_map}
                        />
                    )}
                    {(showLegends || isAnyFilterisChecked || selectedCities.length) && (
                        <Legends
                            selectedFiles={selectedFiles}
                            filterSelections={filterSelections}
                            commercialCities={Boolean(selectedCities.length)}
                        />

                    )}

                    <ChangeLog showInfo={showInfo} setShowInfo={setShowInfo} />
                    {selectedFiles?.includes("socio-economic") && <PopupSocioEcnomics close={closeSocioEconomicsPopup} setClose={setCloseSocioEconomicsPopup} selectedFiles={selectedFiles} />}
                </MapContainer>

                <FeedbackButton isSidebarOpen={isSidebarOpen} />

                <MainSidebar
                    selectedFiles={selectedFiles}
                    loadingState={loadingState}
                    fetchRelevantFile={fetchRelevantFile}
                    onSearchResult={handleSearchResult}
                    setIsSidebarOpen={setIsSidebarOpen}
                    isSidebarOpen={isSidebarOpen}
                    filterSelections={filterSelections}
                    setFilterSelections={setFilterSelections}
                    setRailwaySidingRegions={setRailwaySidingRegions}
                    isRailwayRouting={isRailwayRouting}
                    directionMode={directionMode}
                    setDirectionMode={setDirectionMode}
                    setIsRailwayRouting={setIsRailwayRouting}
                    setDirectionPoints={setDirectionPoints}
                    directionPoints={directionPoints}
                    showShortestRoute={showShortestRoute}
                    setShowShorttestRoute={setShowShorttestRoute}
                    showLongestRoute={showLongestRoute}
                    setShowLongestRoute={setShowLongestRoute}
                    setSourceStation={setSourceStation}
                    setDestinationStation={setDestinationStation}
                    sourceStation={sourceStation}
                    destinationStation={destinationStation}
                    moveToLausitzMap={moveToLausitzMap}
                    selectedRailwayMapStyle={selectedRailwayMapStyle}
                    setSelectedRailwayMapStyle={setSelectedRailwayMapStyle}
                    setRadius={setRadius}
                    radius={radius}
                    setIsSearchedClicked={setIsSearchedClicked}
                    setSelectedCategoryID={setSelectedCategoryID}
                    selectedCategoryID={selectedCategoryID}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                    setShowInfo={setShowInfo}
                />
                {/* Show Statistics Data Table */}
                {selectedFiles.includes("socio-economic") &&
                    !showTollPopup &&
                    selectedRegion &&
                    openSidebar &&
                    selectedRegionId && (
                        <PieChartSidebar
                            data={regionDdata}
                            clickedLocationInfo={clickedLocationInfo}
                            selectedRegion={selectedRegion}
                            selectedRegionId={selectedRegionId}
                            onClose={handleCloseSideBar}
                            state={state}
                            selectedRegionFriendlyURL={selectedRegionFriendlyURL}
                        />
                    )}
                {/* Show Terminal Details */}
                {selectedTerminal && (
                    <TerminalSideBar
                        selectedTerminal={selectedTerminal}
                        onClose={() => handleCloseSideBar("terminal")}
                        toRight={isSidebarOpen}
                    />
                )}
                {selectedInLandPort && (
                    <TerminalSideBar
                        selectedTerminal={selectedInLandPort}
                        onClose={() => handleCloseSideBar("inland")}
                        toRight={isSidebarOpen}
                        isInlandPort
                    />
                )}
                {/* Show Routes Data Table */}
                {openSteckenSideMap && (
                    <SideBar
                        data={steckenSideMapData}
                        selectedRegion={"Strecken"}
                        onClose={handleCloseSideBar}
                    />
                )}
                {/* Show Toll Data Table */}
                {showTollPopup && (
                    <Modal noMaxWidth onClose={handleCloseSideBar}>
                        <h2 style={{ textAlign: "center" }}>{t("Toll Data")}</h2>
                        <TollDataForm setTollDataFilters={setTollDataFilters} tollDataFilters={tollDataFilters} />
                        <TollDataTable data={tollDetailOfSelectedId} />
                    </Modal>
                )}

                <DataSources
                    filterSelections={filterSelections}
                    selectedFiles={selectedFiles}
                    selectedCategoryID={selectedCategoryID}
                    commercialCities={Boolean(selectedCities.length)}
                />
                {popupOpen && (
                    <Modal onClose={() => setPopupOpen(false)}>
                        <div className="content">
                            <p>
                                {t(
                                    'The unit "tonne-kilometre" is a measure of the actual freight distance travelled, calculated as the product of the transported weight in tonnes and the distance covered in kilometres.'
                                )}
                            </p>
                            <p>
                                {t(
                                    "One tonne-kilometre therefore corresponds to the transport of one tonne of goods over a distance of one kilometre."
                                )}
                            </p>
                        </div>
                    </Modal>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Map;
