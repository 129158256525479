import L from 'leaflet';
import terminalsIconPng from "../../../assets/mapElements/KV Terminals_1.svg"
import PlannedTerminals from "../../../assets/mapElements/planned_terminals.svg"

export const TerminalsIcon = new L.Icon({
    iconUrl: terminalsIconPng,
    iconSize: [40, 60], // Adjust the size of your icon
    iconAnchor: [16, 32], // Adjust the anchor point if needed
    popupAnchor: [0, -32], // Adjust the popup anchor if needed
});

export const PlannedTerminalsIcon = new L.Icon({
    iconUrl: PlannedTerminals,
    iconSize: [40, 60], // Adjust the size of your icon
    iconAnchor: [16, 32], // Adjust the anchor point if needed
    popupAnchor: [0, -32], // Adjust the popup anchor if needed
});
