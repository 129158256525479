import React, { useState, useEffect, useRef } from "react";
import classes from "./infoBox.module.scss";
import { useTranslation } from "react-i18next";
import { FaInfo } from "react-icons/fa";

export default function InfoBox() {
    const { t } = useTranslation("translations");
    const [showInfo, setShowInfo] = useState(false);
    const boxRef = useRef(null);

    const toggleInfo = () => {
        setShowInfo((prevState) => !prevState);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setShowInfo(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    return (
        <div className={classes["main"]} ref={boxRef}>
            <FaInfo
                className={classes["info-icon"]}
                onClick={toggleInfo}
                aria-label="Toggle Info"
            />
            {showInfo && (
                <div className={classes["main-container"]}>
                    <div className={classes["legend-container"]}>
                        <div className={classes.details}>
                            <div className={classes["single-heading"]}>
                                {t("infoBoxHeading")}
                            </div>
                            <div className={classes["single-detail"]} dangerouslySetInnerHTML={{ __html: t('infoBoxText') }}>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
