import React, { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import classes from "./index.module.scss";
import store from "../../store";
import { useTranslation } from "react-i18next";
import plannedRailwaysData from './../../data/planned_railways.json'

const ALLOWED_KEYS = [
    "IBN",
    "Bundesland",
    "Projektziele gem. InvKG",
    "Abschnitt",
    "durch BLKG beschlossen",
    "Planungsstand",
    "vsl. Abschluss Lph 4 \n(Abschluss Planung)",
    "Bemerkungen"
];

const PlannedRailways = () => {
    const { mapColors = {} } = store((state) => state) || {};
    const { t, i18n } = useTranslation("translations");
    const [geoJSONKey, setGeoJSONKey] = useState(0);

    const getColor = () => {
        let color = "nonElectrified"
        return mapColors.railwayMap[color];
    };

    const onEachFeature = (feature, layer) => {
        if (Object.keys(feature.properties).length) {
            let popupContent = "";
            const details = feature.properties;
            popupContent += `<table class=${classes["railway-table-container"]
                }><tr><th>${t("Attribute")}</th><th>${t("Value")}</th></tr>`;
            if (Object.keys(details).length > 0) {

                for (let [key, value] of Object.entries(details)) {
                    if (ALLOWED_KEYS.includes(key)) {
                        let k = key
                        k = t("tags." + k);
                        let v = value
                        popupContent += `<tr><td>${k}</td><td style="">${v}</td></tr>`;
                    }
                }
            }
            popupContent += `</table>`;
            layer.bindPopup(popupContent);
        }
    };

    const getLineStyle = (feature) => {
        const { electrified = undefined, ref } = feature.properties || {};
        return {
            color: getColor(electrified, ref),
            weight: 3,
            zIndex: 2,
        };
    };

    useEffect(() => {
        setGeoJSONKey((prevKey) => prevKey + 1);
    }, [i18n.language]);

    return (
        <div>
            <GeoJSON
                key={geoJSONKey}
                data={plannedRailwaysData}
                onEachFeature={onEachFeature}
                style={getLineStyle}
            />
        </div>
    );
};

export default PlannedRailways;
