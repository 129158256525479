import React from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { IndustryIcon } from "../icons/Indusrty.icon.js";
import industriesData from 'data/industries.json'
import classes from "./commercialIndustries.module.scss";
import { useTranslation } from 'react-i18next';

const NAME_KEY = "Name Gewerbe- Industriegebiet";

export const GEOCommercialIndustries = ({ selectedCities = [] }) => {
    const { t } = useTranslation("translations");
    const getIndustryDetails = (company) => {
        return {
            name: company[NAME_KEY],
            city: company['city'],
            lat: company.lat,
            lng: company.lng
        };
    };


    const markers = selectedCities.flatMap((city) => {
        const cityCompanies = industriesData[city] || [];
        return cityCompanies.map((company, index) => {
            const companyDetails = getIndustryDetails(company);
            const { lat, lng, name, city: mycity } = companyDetails;
            return (
                <Marker key={`${city}-${index}`} position={[lat, lng]} icon={IndustryIcon}>
                    <Popup>
                        <div className={classes["railway-siding-popup"]}>
                            <h3>{name}</h3>
                            <p>{mycity}</p>
                        </div>
                    </Popup>
                </Marker>
            );
        });
    });

    return (
        <MarkerClusterGroup>
            {markers}
        </MarkerClusterGroup>
    );
};
