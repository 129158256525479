import React from "react";
import classes from "./tollDataTable.module.scss";
import { useTranslation } from "react-i18next";

const THEAD = [
  "Gewichtsklasse",
  "Gebührensumme Ist [€]",
  "Summe mautpflichtiger Fahrzeugkilometer",
  "Anzahl Abrechnungssätze",
];

function parseGermanNumber(numberStr) {
  if (typeof numberStr === "string") {
    return parseFloat(numberStr.replace(/\./g, "").replace(",", "."));
  }
  return numberStr;
}

function aggregateData(data) {
  const aggregatedData = data.reduce((acc, curr) => {
    const gewichtsklasse = curr["Gewichtsklasse"];
    if (!acc[gewichtsklasse]) {
      acc[gewichtsklasse] = {
        Gewichtsklasse: gewichtsklasse,
        "Gebührensumme Ist": 0,
        "Summe mautpflichtige Kilometer": 0,
        "Anzahl Abrechnungssätze": 0,
      };
    }
    acc[gewichtsklasse]["Gebührensumme Ist"] +=
      parseGermanNumber(curr["Gebührensumme Ist"]) || 0;
    acc[gewichtsklasse]["Summe mautpflichtige Kilometer"] +=
      parseGermanNumber(curr["Summe mautpflichtige Kilometer"]) || 0;
    acc[gewichtsklasse]["Anzahl Abrechnungssätze"] +=
      parseGermanNumber(curr["Anzahl Abrechnungssätze"]) || 0;

    return acc;
  }, {});

  return Object.values(aggregatedData);
}

export default function TollDataTable({ data = [] }) {
    const wholeData = aggregateData(data);

    const sortOrder = [
        "Größer gleich 7,5 t kleiner 12 t",
        "Größer gleich 12 t kleiner gleich 18 t",
        "Größer 18 t"
    ];

    const aggregatedData = wholeData.sort((a, b) => {
        return sortOrder.indexOf(a.Gewichtsklasse) - sortOrder.indexOf(b.Gewichtsklasse);
    });


  const { i18n } = useTranslation();
  const { t } = useTranslation("translations");

  return (
    <div className={classes.container}>
      {/* <p className={classes.note}><span>{t("Note")}:</span> {t('tollDataNote')}</p> */}
      {data.length > 0 && (
        <div>
          <p style={{ fontWeight: 700 }}>{t("Streckenabschnitt")}: </p>
          <p>{data[0]["Streckenabschnitt"]}</p>
        </div>
      )}
      <table className={classes.dataTable}>
        <thead>
          <tr>
            {THEAD.map((th) => (
              <th key={th}>{t(th)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!data.length ? (
            <tr>
              <td colSpan={THEAD.length} style={{ textAlign: "center" }}>
                {t("No data")}
              </td>
            </tr>
          ) : (
            aggregatedData.map((d, index) => {
              let gewichtsklasse = d["Gewichtsklasse"];
              let gebuhrensumme = d["Gebührensumme Ist"] || 0;
              let summe = d["Summe mautpflichtige Kilometer"] || 0;
              let anzahl = d["Anzahl Abrechnungssätze"] || 0;

              if (gebuhrensumme && i18n.language !== "en") {
                gebuhrensumme = gebuhrensumme.toLocaleString("de-DE");
              }
              if (summe && i18n.language !== "en") {
                summe = summe.toLocaleString("de-DE");
              }
              if (anzahl && i18n.language !== "en") {
                anzahl = anzahl.toLocaleString("de-DE");
              }

              return (
                <tr key={index}>
                  <td>{t(gewichtsklasse)}</td>
                  <td>{gebuhrensumme}</td>
                  <td>{summe}</td>
                  <td>{anzahl}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
