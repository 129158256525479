import React from "react";
import classes from "./logoPlaceholder.module.scss";

export default function LogoPlaceholder({ isSidebarOpen = false, setShowInfo = () => { }, }) {
    return (
        <div
            className={`${classes.container} ${isSidebarOpen ? classes.open : ""}`}
        >
            <p onClick={() => setShowInfo((prev) => !prev)}>Demo v1.1</p>
        </div>
    );
}
