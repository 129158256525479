import React, { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import MaustraBenLausitzJSON from "../../data/Database/Maustraßen_Lausitz.json";
import store from "../../store/index.js";
import { axiosInstance } from "../../axios/axiosIntercepter.js";

export default function TollData({
    setTollDetailOfSelectedId = () => { },
    setShowTollPopup = () => { },
    tollDataFilters,
}) {
    const { mapColors = {} } = store((state) => state) || {};
    const [selectedId, setSelectedId] = useState(null);
    const { month, year } = tollDataFilters;

    const getData = async (id) => {
        console.log({ month, year });
        try {
            const res = await axiosInstance.get(
                `https://core-backend.distill-lausitz.de/api/getMautdatenBund?id=${id}&langauge=DE&year=${year}&month=${month}`
            );
            setTollDetailOfSelectedId(res?.data);
            setShowTollPopup(true);
        } catch (err) {
            console.error("Error fetching toll data:", err);
        }
    };

    useEffect(() => {
        if (selectedId) {
            getData(selectedId);
        }
    }, [tollDataFilters]);

    return (
        <>
            <GeoJSON
                data={MaustraBenLausitzJSON.features}
                style={() => ({
                    color: mapColors.tollData.root,
                    weight: 2,
                    fillOpacity: 0.15,
                    fillColor: "#ddc6e1",
                })}
                onEachFeature={(feature, layer) => {
                    layer.on({
                        click: async (event) => {
                            const id = feature.properties.Abschnitts_ID;
                            setSelectedId(id);
                            await getData(id);
                        },
                    });
                }}
            />

            <GeoJSON
                data={MaustraBenLausitzJSON.features}
                style={() => ({
                    color: "transparent",
                    weight: 6,
                    fillOpacity: 0.15,
                })}
                onEachFeature={(feature, layer) => {
                    layer.on({
                        click: async (event) => {
                            const id = feature.properties.Abschnitts_ID;
                            setSelectedId(id);
                            await getData(id);
                        },
                    });
                }}
            />
        </>
    );
}
