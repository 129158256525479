import React, { useMemo } from "react";
import classes from "./legendCard.module.scss";
import { useTranslation } from "react-i18next";
import store from "../../../store";
import { buildLegendList } from "../../../utils/legend-card";

export default function LegendCard({
    removeHeading = false,
    selectedFiles = [],
    filterSelections = {},
    commercialCities = false
}) {
    const { t } = useTranslation("sidebar");
    const { t: translation } = useTranslation("translations");
    const { mapColors = {} } = store((state) => state) || {};

    // Memoize the LIST of legend items to improve performance
    const legendList = useMemo(() => {
        return buildLegendList(t, translation, filterSelections, mapColors);
    }, [t, translation, filterSelections, mapColors]);

    // Filter list based on selected files or include items with no id
    const filteredList = useMemo(() => {
        let updatedSelectedFiles = [...selectedFiles]; // Create a new array to avoid mutating the original

        if (commercialCities) {
            if (!updatedSelectedFiles.includes('commercialCities')) {
                updatedSelectedFiles.push('commercialCities'); // Add only if not already present
            }
        } else {
            updatedSelectedFiles = updatedSelectedFiles.filter(s => s !== 'commercialCities'); // Remove if present
        }

        return legendList.filter(
            (item) => updatedSelectedFiles.includes(item.id) || item.id === undefined
        );
    }, [legendList, selectedFiles, commercialCities]);

    return (
        <div
            className={`${classes["main-container"]} ${removeHeading ? classes.remove : ""
                }`}
        >
            <div
                className={`${classes["legend-container"]} ${removeHeading ? classes.remove : ""
                    }`}
            >
                {!removeHeading && (
                    <p className={classes.heading}>{t("tabs.legend")}</p>
                )}

                <div
                    className={`${classes.details} ${removeHeading ? classes.remove : ""
                        }`}
                >
                    <div className={classes["single-detail"]}>
                        <div className={classes["values-container"]}>
                            {filteredList.map((v) => (
                                <div className={classes["label-and-color"]} key={v.label}>
                                    <div
                                        style={{
                                            background: v.color,
                                        }}
                                        className={classes["color"]}
                                    >
                                        {v.icon && <img src={v.icon} alt="" />}
                                    </div>
                                    <p>{v.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
