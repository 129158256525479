import { Marker, useMap } from "react-leaflet";
import { TerminalsIcon, PlannedTerminalsIcon } from "../icons/Terminals.icon";
import React, { useEffect } from "react";
import TerminalData from "../../../data/DiSTILL_Terminaldata-Tool_WGS84-EPSG4326.json";

export const GEOKVTerminals = ({
    selectedFiles,
    handleTerminals,
    flyBackFunction,
    geplant = false,
    kvTerminal = false
}) => {
    const map = useMap();

    const getFeatures = () => {
        if (geplant && kvTerminal) {
            return TerminalData.features
        }
        return TerminalData.features.filter(f =>
            geplant
                ? f?.properties?.status === "geplant"
                : !f?.properties?.status || f.properties.status !== "geplant"
        )
    }


    const filteredTerminals = React.useMemo(() => {
        return {
            ...TerminalData,
            features: getFeatures(),
        };
    }, [geplant, kvTerminal]);

    const flyBackToInitialView = () => {
        map.flyTo([51.5349597, 13.6215388], 8.73, { animate: true });
    };

    useEffect(() => {
        if (flyBackFunction) {
            flyBackToInitialView();
        }
    }, [flyBackFunction]);

    const handleMarkerClick = (event, markerPosition, markerData) => {
        map.flyTo(markerPosition, 15, { animate: true });
        handleTerminals(markerData);
    };

    return (
        <>
            {filteredTerminals.features.map((data, index) => {
                const position = [data.geometry.coordinates[1], data.geometry.coordinates[0]];
                const geplant = data?.properties?.status === "geplant"
                return (
                    <Marker
                        key={index}
                        position={position}
                        icon={geplant ? PlannedTerminalsIcon : TerminalsIcon}
                        eventHandlers={{
                            click: (event) => handleMarkerClick(event, position, data),
                        }}
                    />
                );
            })}
        </>
    );
};
