import React from 'react';
import classes from './tollDataForm.module.scss';
import { useTranslation } from 'react-i18next';

const TollDataForm = ({
    setTollDataFilters = () => { },
    tollDataFilters,
}) => {
    const { t } = useTranslation('translations');
    const currentYear = 2024;

    const years = Array.from(
        { length: currentYear - 2022 + 1 },
        (_, i) => 2022 + i
    ).reverse();

    const months = [
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
    ];


    const handleSelection = (type = "", value = "") => {
        if (!['year', 'month'].includes(type)) return;

        setTollDataFilters((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    return (
        <div className={classes.tollForm}>
            <select
                id="monthSelector"
                onChange={(e) => handleSelection('month', e.target.value)}
                className={classes.select}
                value={tollDataFilters.month}
            >
                <option value="">{t('Select Month')}</option>
                {months.map((month) => (
                    <option key={month.value} value={month.value}>
                        {t(month.name)}
                    </option>
                ))}
            </select>
            <select
                id="yearSelector"
                onChange={(e) => handleSelection('year', e.target.value)}
                className={classes.select}
                value={tollDataFilters.year}
            >
                <option value="">{t('Select Year')}</option>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>

        </div>
    );
};

export default TollDataForm;
