import React, { useMemo } from "react";
import classes from "./details.module.scss";
import { useTranslation } from "react-i18next";

export default function TerminalDetails({ details, geometry }) {
    const { t } = useTranslation("translations");

    const leftSideData = useMemo(() => {
        if (!details) return [];

        const fields = [
            {
                key: "opening_ho",
                icon: "./images/clock.svg",
                title: t("opening_hours"),
            },
            {
                key: "technical_",
                icon: "./images/settings.svg",
                title: t("technical_equipment"),
            },
            {
                key: "services",
                icon: "./images/settings-2.svg",
                title: t("services"),
            },
            {
                key: "loading_tr",
                icon: "./images/tracks.svg",
                title: t("loading_tracks"),
            },
        ];

        return fields.map(({ key, icon, title }) => {
            const value = details[key];
            const formattedValue =
                typeof value === "string" && value.toLowerCase() === "n.a."
                    ? "N.A."
                    : value || "N.A.";
            return {
                icon,
                title,
                value: Array.isArray(formattedValue)
                    ? formattedValue?.join(", ")
                    : formattedValue,
            };
        });
    }, [details, t]);

    const rightSide = useMemo(() => {
        if (!details) return [];

        const fields = [
            {
                key: "daily_conn",
                icon: "./images/global.svg",
                title: t("daily_connections"),
            },
            {
                key: "loading_un",
                icon: "./images/units.svg",
                title: t("loading_units"),
            },
            {
                key: "longitude",
                icon: "./images/longitude.svg",
                title: t("longitude"),
            },
            {
                key: "latitude",
                icon: "./images/latitude.svg",
                title: t("latitude"),
            },
            {
                key: "status",
                icon: "./images/icon-info.svg",
                title: t("status"),
            },
        ];

        const getValue = (key) => {
            switch (key) {
                case "longitude":
                    return geometry.coordinates[0];
                case "latitude":
                    return geometry.coordinates[1];
                case "status":
                    return t(details[key])
                default:
                    return details[key];
            }
        };

        return fields.map(({ key, icon, title }) => {
            const value = getValue(key);
            const formattedValue =
                typeof value === "string" && value.toLowerCase() === "n.a."
                    ? "N.A."
                    : value || "N.A.";
            return {
                icon,
                title,
                value: Array.isArray(formattedValue)
                    ? formattedValue?.join(", ")
                    : formattedValue,
            };
        });
    }, [details, t]);



    return (
        <div className={classes["main-container"]}>
            <div className={classes.container}>
                <div className={classes["content-container"]}>
                    <div className={classes.left}>
                        {leftSideData.map((data) => (
                            <div
                                className={`${classes["single-card"]} ${data.minHeight ? classes["min-height"] : ""
                                    }`}
                            >
                                <div className={classes["icon-container"]}>
                                    <img alt={data.title} src={data.icon} />
                                </div>
                                <div className={classes.details}>
                                    <p className={classes.heading}>{data.title}</p>
                                    <p className={classes.value}>{data.value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes.right}>
                        {rightSide.map((data) => (
                            <div className={classes["single-card"]}>
                                <div className={classes["icon-container"]}>
                                    <img alt={data.title} src={data.icon} />
                                </div>
                                <div className={classes.details}>
                                    <p className={classes.heading}>{data.title}</p>
                                    <p className={classes.value}>{data.value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {details.source && (
                <p className={classes.source}>
                    <b>Source</b>: {details.source}
                </p>
            )}
        </div>
    );
}
